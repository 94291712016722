import React from "react";
import { Container, Row, Col } from "reactstrap";
import PrismicReact from "prismic-reactjs";
import Footer from "../Components/Styled/Footer";
import { openCart, closeCart } from "../State/cart/actions";
import { connect } from "react-redux";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import BuyButton from "../Components/Styled/BuyButton";
import { StyledInput } from "../Components/Styled/Form";
import { Title, BodyText } from "../Components/Styled/Type";

const url =
  "//rocks.us10.list-manage.com/subscribe/post?u=ccf0f1e9806bc6c93272f0d5e&amp;id=af6fe3f9fc";
const SimpleForm = () => <MailchimpSubscribe url={url} />;

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <StyledInput
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <BuyButton onClick={submit}>Submit</BuyButton>
    </>
  );
};

const MailchimpForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <CustomForm onSubmitted={formData => subscribe(formData)} />
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div style={{ color: "green" }}>Subscribed !</div>
        )}
      </div>
    )}
  />
);

const NavItems = ({ document, linkResolver }) => {
  const { data: { body = false } = {} } = { ...document };
  return body
    ? body.map((navitem, key) => {
        let {
          navigation_link,
          navigation_name,
          internal_link
        } = navitem.primary;
        return (
          <NavLink
            key={key}
            to={
              internal_link
                ? internal_link
                : PrismicReact.Link.url(navigation_link, linkResolver)
            }
          >
            {navigation_name[0].text}
          </NavLink>
        );
      })
    : null;
};

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      docMain: null,
      docFooter: null,
      notFound: false
    };
  }

  componentWillMount() {
    this.fetchNav(this.props);
    this.fetchFooter(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchNav(props);
    this.fetchFooter(props);
  }

  fetchNav(props) {
    return (
      (props.prismicCtx &&
        props.prismicCtx.api.getByUID(
          "main_navigation",
          "navigation-main",
          (err, doc) => {
            if (doc) {
              this.setState({ docMain: doc });
            } 
          }
        )) ||
      false
    );
  }

  fetchFooter(props) {
    return (
      (props.prismicCtx &&
        props.prismicCtx.api.getByUID(
          "main_navigation",
          "footer",
          (err, doc) => {
            if (doc) {
              this.setState({ docFooter: doc });
            }
          }
        )) ||
      false
    );
  }

  linkResolver = function(doc) {
    if (doc.type === "blog") return "/post/" + doc.uid;
    if (doc.type === "page" || "homepage") return "/" + doc.uid;
    return null;
  };

  render() {
    const { docMain, docFooter, isOpen, expand } = this.state;
    const {
      location: { pathname }
    } = this.props;
    return ( pathname !== "/stream") ? 
      <Footer className="color_section">
        <Container style={{ display: "flex", height: "100%", paddingTop: 20 }}>
          <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Row>
                <Col xs={3}>
                  <Title small>Explore</Title>
                  <NavItems document={docMain} linkResolver={this.linkResolver} />
                </Col>
                <Col xs={3}>
                  <Title small>Details</Title>

                  <NavItems document={docFooter} linkResolver={this.linkResolver} />
                </Col>
                <Col md={{size: 4, offset: 2}}>
                  <Title small>Keep up to date</Title>
                  <MailchimpForm />
                  <IconContext.Provider value={{ color: "white", size: 30 }}>
                    <FaTwitter />
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "white", size: 30 }}>
                    <FaFacebookF />
                  </IconContext.Provider>
                  <IconContext.Provider value={{ color: "white", size: 30 }}>
                    <FaInstagram />
                  </IconContext.Provider>
                </Col>
              </Row>
            </div>
            <div style={{ flexShrink: 1 }}>
              <Row>
                <BodyText
                  small
                >{`Copyright \xA9 ${new Date().getFullYear()} Those Ltd. -  All Rights Reserved`}</BodyText>
              </Row>
            </div>
          </div>
        </Container>
      </Footer>
      :null 
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.cart.isOpen
  };
};

const mapDispatchToProps = {
  openCart,
  closeCart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);

// Helper styles for demo

import React from "react";
import { Formik, withFormik, ErrorMessage } from "formik";
import { Form, FormGroup, Label, FormText } from "reactstrap";
import {StyledInput, ErrorContainer} from '../Components/Styled/Form';
import Button from '../Components/Styled/BuyButton';
import {cognitoLogin} from '../State/authentication/operations';
import {compose} from 'recompose';
import { connect } from 'react-redux';

import * as Yup from "yup";


const MyForm = props => {
  const { values, handleChange, handleBlur, handleSubmit } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <StyledInput
          type="text"
          placeholder="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          name="email"
        />
        <ErrorContainer>
          <ErrorMessage name="email" />
        </ErrorContainer>
      </FormGroup>
      <FormGroup>
        <StyledInput
          type="password"
          placeholder="Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          name="password"
        />
        <ErrorMessage name="password" />
      </FormGroup>
      <Button type="submit">Login</Button>
    </Form>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: () => ({ email: "", password: "" }),
  validationSchema: () =>
    Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required")
    }),
  handleSubmit: (values, { props, setSubmitting }) => {
    console.log(values.email);
    console.log(values.password);

    props.cognitoLogin(values.email, values.password);
    //setSubmitting(false);
  },
  displayName: "BasicForm"
});

// const mapStateToProps = state => {
//   return {
//     currentDevice: getCurrentDevice(state),
//     deviceStatus: getCurrentStatus(state),
//     loading: state.devices.loading,
//     draftJot: state.create.draftJot
//   };
// };

const mapDispatchToProps = {
  cognitoLogin
};

const enhance = compose(
  connect(
    null,
    mapDispatchToProps
  ),
  MyEnhancedForm
)

export default enhance(MyForm);

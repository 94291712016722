import React from "react";
import { Title, SubTitle, BodyText, Link } from "../Components/Styled/Type";
import { Container, Row, Col, CardDeck } from "reactstrap";
import Card from "../Components/Elements/Card";
import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import { FiPlus } from "react-icons/fi";
import {userJotsRequest} from "../State/profile/actions";



const mapStateToProps = state => {
  return {
    jots: state.profile.jots
  };
};

const mapDispatchToProps = {
  userJotsRequest
};


const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount(){
      this.props.userJotsRequest({});
    }
  })
);

const UserJots = ({jots}) => {
  console.log(jots);
  return (
    <CardDeck>
       <Col lg={3} style={{ marginBottom: "2rem" }}>
        <Card centered link={"/create"}>
          <IconContext.Provider value={{ color: "blue", size: 100 }}>
            <FiPlus />
          </IconContext.Provider>
          <SubTitle>Create Jot</SubTitle>
        </Card>
      </Col>
      {jots.allIds.map(id => {
        let {jotId, title, description, pngFilename, userId} = jots.byId[id];
        let highres = true;
        return<Col lg={3} style={{ marginBottom: "2rem" }}>
        <Card
          jot
          small
          type={jotId}
          title={title}
          url={`http://joto.imgix.net/public/${userId}/jots/${pngFilename}?w=${
            highres ? 800 : 200
          }`}
          // text={description}
          link={`/edit/${id}`}
        />
      </Col>
      })}
     
    </CardDeck>
  );
};

export default enhance(UserJots);

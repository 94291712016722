// In src/Page.js

import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slices from "../Containers/Slices";
import NotFound from "../Screens/NotFound";
import BodyWrapper from "../Components/Styled/BodyWrapper";
// Declare your component
export default class Page extends React.Component {
  state = {
    content: null
  };

  componentDidMount() {
    fetch("https://www.iubenda.com/privacy-policy/58991274/full-legal", { crossDomain:true,   headers: {'Content-Type':'application/json'},
  }).then(response => {
      console.log("RESPONSE", response.body);
      this.setState({ content: response.content })

    }
    );
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    console.log(props);
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid

      return props.prismicCtx.api.getByUID(
        "page",
        props.match.params.uid,
        {},
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          } else {
            this.setState({ notFound: !doc });
          }
        }
      );
    }
    return null;
  }

  render() {
    return this.state.content ? (
      <BodyWrapper>
        <Container>
          <Row>
            <Col md={12}>{this.state.content}</Col>
          </Row>
        </Container>
      </BodyWrapper>
    ) : null;
  }
}

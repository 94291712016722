import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  transform: ${props => props.open ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.15s ease-in-out;
  z-index: 100000;
  
  @media (min-width: 768px){
    width: 350px;
  } 
`;

export const Close = styled.button`
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 35px;
    color: #999;
    border: none;
    background: transparent;
    transition: transform 100ms ease;
    cursor: pointer;
`;

export const Footer = styled.footer`
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;

  // TODO:
  .Cart__checkout{
    margin-top: 20px;
    display: block;
    width: 100%;
    background: $second;
  }
`;

export const Header = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
    flex: 0 0 auto;
    display: inline-block;
`;

export const Total = styled.div`
float: left;
text-transform: uppercase;
line-height: 1;
font-size: 24px;

> .Cart-info__pricing {
  line-height: 1;
  .pricing{
    font-size: 24px;
    line-height: 1;
    font-family: 'AvenirLTBold';
  }
}

&.Cart-info__small{
  line-height: 1;
  font-size: 16px;
  font-family: 'AvenirLT-Roman';
  & + .Cart-info__pricing {
    line-height: 1;
    .pricing{
      font-family: 'AvenirLT-Roman';
      font-size: 16px;
    }
  }
}
  
`;

export const Pricing = styled.div`
  float: right;

  .pricing {
    margin-left: 5px;
    font-size: 16px;
    color: black;
    font-family: "AvenirLT-Roman";
  }
`;

export const Items = styled.ul`
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
`;
import types from './types';
import _ from 'lodash';
import { combineReducers } from 'redux';

const objFromArray = array => {
  return array.reduce((accumulator, current) => {
    accumulator[current.deviceId] = current;
    return accumulator;
  }, {});
};

const idArray = array => {
  return array.map(device => device.deviceId);
};

const addDevicesById = (state, action) => {
  const { devices } = action;
  const devicesById = objFromArray(devices);
  return {
    ...state,
    ...devicesById
  };
};

const devicesById = (state = {}, action) => {
  switch (action.type) {
    case types.DEVICES_LIST_SUCCESS:
      return addDevicesById(state, action);
    case types.DEVICES_LIST_REQUEST:
      return {};
    default:
      return state;
  }
};

const addDeviceIds = (state, action) => {
  const { devices } = action;
  const deviceIds = idArray(devices);
  return _.uniq(state.concat(deviceIds));
};

const allDeviceIds = (state = [], action) => {
  switch (action.type) {
    case types.DEVICES_LIST_SUCCESS:
      return addDeviceIds(state, action);
    case types.DEVICES_LIST_REQUEST:
      return [];
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.DEVICES_REQUEST:
      return true;
    case types.DEVICES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case types.DEVICES_SUCCESS:
      return action.devices.length > 0
        ? action.devices
          ? action.devices[0].deviceId
          : state
        : state;
    case types.DEVICE_SELECT:
      return action.deviceId;
    default:
      return state;
  }
};

export default combineReducers({
  byId: devicesById,
  allIds: allDeviceIds,
  loading,
  selected
});

import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "../Components/Elements/Card";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import { compose, lifecycle, withState } from "recompose";
import { apiGetJot } from "../Api";
import Tags from "../Components/Elements/Tags";
import { Title, SubTitle, BodyText, Link } from "../Components/Styled/Type";
import Notice from "../Components/Styled/Notice";

const enhance = compose(
  withState("jot", "setJot", false),
  lifecycle({
    async componentDidMount() {
      let jot = await apiGetJot(this.props.match.params.id);
      this.props.setJot(jot);
      console.log(jot);
    }
  })
);

const Edit = ({
  jot: {
    jotId,
    title,
    description,
    tags,
    categories,
    website,
    social,
    userId,
    pngFilename
  }
}) => (
  <BodyWrapper>
    <Container className={"d-flex h-100 flex-grow-1"}>
      <Row className={"justify-content-center align-self-center flex-grow-1"}>
        <Col lg={6}>
          <Card create centered jot url={`http://joto.imgix.net/public/${userId}/jots/${pngFilename}?w=2000`}/>
        </Col>
        <Col lg={{ size: 5, offset: 1 }}>
          {jotId && <SubTitle small>{jotId}</SubTitle>}
          {title && <Title small>{title}</Title>}
          {description && <BodyText>{description}</BodyText>}
          {website && <Link>{website}</Link>}
          {tags && <Tags tags={tags} />}
          {categories && <Tags isCategory tags={categories} />}
          {/* <Categories/> */}
          <Notice>
            Editing of Jots is coming soon.
          </Notice>
        </Col>
      </Row>
    </Container>
  </BodyWrapper>
);

export default enhance(Edit);


import types from "./types";
import _ from 'lodash';
import { combineReducers } from 'redux'

const loading = (state = false, action) => {
  switch (action.type) {
    case types.CURRENT_VERSION_REQUEST:
      return true
    case types.CURRENT_VERSION_SUCCESS:
      return false
    default:
      return state
  }
}

const latest = (state = null, action) => {
  switch (action.type) {
    case types.CURRENT_VERSION_SUCCESS:
      return action.firmware;
    default:
      return state
  }
}


const firmware = (state = null, action) => {
  switch (action.type) {
    case types.CURRENT_VERSION_SUCCESS:
      return action.firmware;
    default:
      return state
  }
}

export default combineReducers({
  latest: latest,
  versions: firmware,
  loading
})



import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";



export const Container = styled.div`
  justify-content: space-around;
  align-self: center;
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: ${props => (props.create ? 0 : "100%")};
  overflow: hidden;
  padding-bottom: ${props => (props.create ? "125%" : 0)};
  cursor: ${props => props.link ? "pointer" : "default"};

  &:hover,
    &:focus {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
    }

    ${breakpoint("md")`
      margin-bottom: 0;
  `}
`;

export const Body = styled.div`
  padding-top: ${props => props.small ? props.theme.spacing.xs : props.theme.spacing.s/1.5}vw;
  padding-left: ${props => props.small ? props.theme.spacing.s : props.theme.spacing.m/1.5}vw;
  padding-right: ${props => props.small ? props.theme.spacing.s : props.theme.spacing.m/1.5}vw;
  padding-bottom: ${props => props.small ? props.theme.spacing.s : props.theme.spacing.m/1.5}vw;
  flex-direction: column;
  text-align: ${props => (props.center ? "center" : "left")};
  align-items: ${props => (props.center ? "center" : "flex-start")};
  justify-content: ${props => (props.center ? "center" : "flex-start")};
  height: auto;
  margin-bottom: ${props=>props.theme.spacing.s}rem;
  

   ${breakpoint("md")`
  `}

`;

export const Title = styled.h2`
  font-size: ${props =>
    props.small ? props.theme.font.h2.smallCard : props.theme.font.h2.card}rem;
  line-height: ${props => props.theme.font.h2.lineHeight};
  margin: 0;
  margin-top: ${props =>
    props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;
  margin-bottom: 0;
  text-align: ${props => (props.center ? "center" : "left")};

  p + &{
    margin-bottom: ${props =>
    props.small ? props.theme.spacing.xs : props.theme.spacing.xs}rem;
  }
`;

export const Category = styled.h4`
  font-size: ${props =>
    props.small ? props.theme.font.h4.smallCard : props.theme.font.h4.card}rem;
  line-height: ${props => props.theme.font.h4.lineHeight*0.8};
  color: ${props => props.theme.color.first};
  text-transform: uppercase;
  margin-top: 0;
  text-align: ${props => (props.center ? "center" : "left")};
`;

export const Text = styled.p`
  font-size: ${props => props.theme.font.p.card}rem;
  line-height: ${props => props.theme.font.p.lineHeight*0.9};
  text-align: ${props => (props.center ? "center" : "left")};
  margin-bottom: 0;
`;

export const Image = styled.div`
  /* padding-bottom: 60%; */
  width: 100%;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center center;
  height: 0;
  overflow: hidden;
  padding-bottom: ${props => (props.jot ? "90%" : "60%")};
`;

export const CONNECTION_REQUEST = 'app/connection/CONNECTION_REQUEST';
export const CONNECTION_SUCCESS = 'app/connection/CONNECTION_SUCCESS';
export const CONNECTION_ERROR = 'app/connectionCONNECTION_ERROR/';
export const WIFI_REQUEST = 'app/connection/WIFI_REQUEST';
export const WIFI_SUCCESS = 'app/connection/WIFI_SUCCESS';
export const WIFI_ERROR = 'app/connection/WIFI_ERROR';
export const PAIRING_REQUEST = 'app/connection/PAIRING_REQUEST';
export const PAIRING_SUCCESS = 'app/connection/PAIRING_SUCCESS';
export const PAIRING_ERROR = 'app/connection/PAIRING_ERROR';

export default {
  CONNECTION_REQUEST,
  CONNECTION_SUCCESS,
  CONNECTION_ERROR,
  WIFI_REQUEST,
  WIFI_SUCCESS,
  WIFI_ERROR,
  PAIRING_REQUEST,
  PAIRING_SUCCESS,
  PAIRING_ERROR
};

import Amplify, { Auth, Storage } from "aws-amplify";
import { apiGetJot, apiCreateJot, apiSendJot } from "../../Api";

// TODO: this should probably be passed props and filename maybe not random
import { AmplifyConfig } from "../../config";
Amplify.configure(AmplifyConfig);

export const jotGet = jotId =>
  new Promise((resolve, reject) => {
    apiGetJot(jotId)
      .then(jot => resolve(jot))
      .catch(err => reject(err));
  });

export const createJot = (validsvg, params) =>
  new Promise((resolve, reject) => {
    const filename = `${Math.floor(Date.now() / 1000)}.svg`;
    console.log(validsvg);
    let jotParams = params || {
      title: `${Math.floor(Date.now() / 1000)}`,
      description: "placeholder description",
      categories: ["cat1", "cat2", "cate3"],
      tags: ["test", "jot", "tags"],
      svgFilename: filename
    };

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Storage.put(`${user.username}/jots/${filename}`, validsvg, {
          level: "public",
          contentType: "image/svg+xml"
        });
      })
      .then(response => {
        jotParams.svgFilename = filename;
        return apiCreateJot(jotParams);
      })
      .then(jot => {
        return resolve(jot);
      })
      .catch(err => reject(err));
  });

export const jotSend = (deviceId, jotId) =>
  new Promise((resolve, reject) => {
    apiSendJot(deviceId, jotId)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });

import React from "react";
import { Container, Row, Col } from "reactstrap";
import * as Card from "../Components/Styled/Card";
import NavBar from "../Containers/Navbar";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import LoginForm from "../Containers/LoginForm";
import { Title, BodyText, SubTitle } from "../Components/Styled/Type";
import PlatformBackground from "../assets/images/platoform.jpg";
import * as LogoBar from "../Components/Styled/LogoBar";
import LogoBarComponent from "../Components/Elements/LogoBar"
import beta from "../assets/images/beta.png";


const LoginScreen = ({
  doc,
  client,
  cartTotal,
  prismicCtx,
  openCart,
  ...rest
}) => {
  return (
    <BodyWrapper>
      <LogoBar.Container>
        <Row>
          <Col md={6}>
            <Card.Container style={{maxWidth: 500, margin: "0 auto"}}>
              <Card.Body>
                <Row>
                  <Col md={6}>
                  <img src={beta} />
                  </Col>
                </Row>
                <Title small>Now open to artists</Title>
                <BodyText>
                  You've probably got hear because you've been invited by the
                  Joto team to join the movement - exciting times!
                </BodyText>
                <BodyText>
                  If you'd like to join the movement - and share your Jots to
                  the world, click here for an invite
                </BodyText>
                <LoginForm />
              </Card.Body>
            </Card.Container>
          </Col>
          <Col md={6}>
            <img src={PlatformBackground} />
          </Col>
        </Row>
      </LogoBar.Container>
      <LogoBar.Bar>
        <Container>
          <LogoBarComponent/>
        </Container>
      </LogoBar.Bar>
    </BodyWrapper>
  );
};

export default LoginScreen;

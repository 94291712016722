import PrismicReact from "prismic-reactjs";
import { withState, withHandlers, lifecycle, compose } from "recompose";

export const withFetchPage = page =>
  compose(
    withState("doc", "setDoc", null),
    withHandlers({
      fetchPage: ({ setDoc, prismicCtx }) => () => {
        console.log(prismicCtx);
        return prismicCtx
          ? prismicCtx.api.getSingle(page, (err, doc) => {
              doc ? setDoc(doc) : setDoc(null);
            })
          : null;
      }
    }),
    lifecycle({
      componentDidMount() {
        this.props.fetchPage(this.props);
      },
      componentWillReceiveProps(props) {
        if (!props.doc) {
          this.props.fetchPage(props);
        }
      }
    })
  );

export const withFetchUID = (type) =>
  compose(
    withState("doc", "setDoc", null),
    withHandlers({
      fetchPage: ({ setDoc, prismicCtx, match }) => () => {
        return prismicCtx
          ? prismicCtx.api.getByUID(type, match.params.id, (err, doc) => {
              doc ? setDoc(doc) : setDoc(null);
            })
          : null;
      }
    }),
    lifecycle({
      componentDidMount() {
        this.props.fetchPage(this.props);
      },
      componentWillReceiveProps(props) {
        if (!props.doc) {
          this.props.fetchPage(props);
        }
      }
    })
  );

export const withFetchID = id =>
  compose(
    withState("doc", "setDoc", null),
    withHandlers({
      fetchPage: ({ setDoc, prismicCtx }) => () => {
        return prismicCtx
          ? prismicCtx.api.getByID(id, (err, doc) => {
              doc ? setDoc(doc) : setDoc(null);
            })
          : null;
      }
    }),
    lifecycle({
      componentDidMount() {
        this.props.fetchPage(this.props);
      },
      componentWillReceiveProps(props) {
        if (!props.doc) {
          this.props.fetchPage(props);
        }
      }
    })
  );

export const fetchByID = (PrismicCtx, id) => PrismicCtx.api.getByID(id, (err, doc) => doc)
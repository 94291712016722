import React from "react";
import PrismicReact, { Link, RichText, Date } from "prismic-reactjs";
import Section from "../Components/Slices/Section";
import ContentBlock from "../Components/Slices/ContentBlock";
import CaseStudy from "../Components/Slices/CaseStudy";
import Collection from "../Components/Slices/Collection";
import Features from "../Components/Slices/Features";
import Quote from "../Components/Slices/Quote";
import Contact from "../Components/Slices/Contact";
import Text from "../Components/Slices/Text";
import TileCards from "../Components/Slices/Tiles";
import Slice from "../Components/Styled/Slice";

const linkResolver = function(doc) {
  // Pretty URLs for known types
  if (doc.type === "blog") return "/post/" + doc.uid;
  if (doc.type === "page") return "/" + doc.uid;
  // Fallback for other types, in case new custom types get created
  return "/doc/" + doc.id;
};

let htmlSerializer = function(type, element, content, children) {
  switch (type) {
    case "embed":
      console.log(element);
      const vimeoEmbed = `<style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src='https://player.vimeo.com${element.oembed.uri.replace(
        "videos",
        "video"
      )}' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>`;
      return (
        <div
          className="video-embed"
          dangerouslySetInnerHTML={{ __html: vimeoEmbed }}
        />
      );
    default:
      return null;
  }
};

const selectSlice = ({ slice, key, ...rest }) => {
  switch (slice.slice_type) {
    case "standard_block":
      return <Slice><ContentBlock key={key} slice={slice} {...rest} /></Slice>;
    case "content_block":
      return  <Slice><ContentBlock key={key} slice={slice} {...rest} /></Slice>;
    // case "use_cases":
    //   return <CaseStudy key={key} slice={slice} {...rest} />;
    // case "contact":
    //   return <Contact key={key} slice={slice} {...rest} />;
    // case "collection":
    //   return <Collection key={key} slice={slice} {...rest} />;
    case "features":
      return <Features key={key} slice={slice} {...rest} />;
    // case "quote":
    //   return <Quote key={key} slice={slice} {...rest} />;
    case "promoted_content":
      return <TileCards promoted key={key} slice={slice} {...rest} />;
    case "blog_post":
      return <TileCards key={key} slice={slice} {...rest} />;
    case "text":
      return RichText.render(slice.primary.text, linkResolver, htmlSerializer);
    case "standard_text":
      return RichText.render(
        slice.primary.body_text,
        linkResolver,
        htmlSerializer
      );
    default:
     return false;
  }
};

const Slices = ({ body, prismicCtx }) => {
  let section = body.some(e => e.slice_type === "section") ? true : false;

  if(section){
    
    let result = body.reduce(function(result, slice, index, arr) {
      slice.slice_type !== "section" && result[result.length - 1].push(slice); // add current item to last sub array
      slice.slice_type === "section" &&
        index < body.length - 1 &&
        result.push([slice]);
      return result;
    }, [])

    return result.map((section, key) => {
      return (
        <Section key={key} {...section[0].primary}>
          {section.map((slice, key) =>  selectSlice({ slice, key, prismicCtx}) )}
        </Section>
      );
    });

  }else{
    return  body.map((slice, key) => {
      return selectSlice({ slice, key, prismicCtx});
    })
  }

};

export default Slices;

import { createGlobalStyle } from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const GlobalStyle = createGlobalStyle`

body {
  font-family: ${props => props.theme.fontFamily};
}

html, body{
}

body{
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

#root{
  /* font-size: 16px;
  display: flex;
  min-height: 100vh;
  flex-direction: column; */
  flex: 1;
  display: flex;
  flex-direction: column;
}

h1{
  ${props => props.theme.setFontWithRhythm(props.theme.font.h1.family, props.theme.font.h1.size, props.theme.font.h1.lineHeight)}
  margin: ${props => props.theme.rhythmSizing(props.theme.font.h1.size*2)}rem 0;
  /* font-size: ${props => props.theme.font.h1.size};
  line-height: ${props => props.theme.font.h1.lineHeight}; */
  h4 + &{
    margin-top: 0.2rem;
  }

  .color_section & {
    color: white;
  }
}

h2{
  ${props => props.theme.setFontWithRhythm(props.theme.font.h2.family, props.theme.font.h2.size, props.theme.font.h2.lineHeight)}
  margin: ${props => props.theme.rhythmSizing(props.theme.font.h2.size*2)}rem 0 ${props => props.theme.rhythmSizing(props.theme.font.h2.size*2)}rem 0;
  color: black;
  p + &{
    margin-bottom: ${props => props.theme.rhythmSizing(props.theme.font.h2.size*2)}rem 0;
  }

  .color_section & {
    color: white;
  }
}

h3{
  ${props => props.theme.setFontWithRhythm(props.theme.font.h4.family, props.theme.font.h4.size, props.theme.font.h4.lineHeight)}
  margin: ${props => props.theme.rhythmSizing(props.theme.font.h4.size*2)}rem 0;
  color: black;

  /* font-family: ${props => props.theme.font.h4.family};
  line-height: ${props => props.theme.font.h4.lineHeight};
  margin-bottom: ${props => props.theme.spacing.m}rem; */

}

h4{
  ${props => props.theme.setFontWithRhythm(props.theme.font.h4.family, props.small ? props.theme.font.h4.smallSize : props.theme.font.h4.size, props.theme.font.h4.lineHeight)}
  margin-top: ${props => props.theme.rhythmSizing(props.theme.spacing.l)}rem;
  margin-bottom: ${props => props.theme.rhythmSizing(props.theme.spacing.s)}rem;
  color: black;
  text-transform: uppercase;

  .color_section & {
    color: white;
  }
}

p{
  ${props => props.theme.setFontWithRhythm(props.theme.font.p.family, props.small ? props.theme.font.p.smallSize :props.theme.font.p.size, props.theme.font.p.lineHeight)}
  margin: ${props => props.theme.rhythmSizing(props.theme.font.p.size*2)}rem 0;
  color: black;

  .color_section & {
    color: white;
  }

  /* font-size: ${props => props.theme.font.p.size};
  font-family: ${props => props.theme.font.p.family};
  line-height: ${props => props.theme.font.p.lineHeight}; */
}

h6{
  ${props => props.theme.setFontWithRhythm(props.theme.font.caption.family, props.theme.font.caption.size, props.theme.font.caption.lineHeight)}
  color: black;

  .block-img + &{
    margin-top: -${props => props.theme.spacing.m - props.theme.spacing.xs}rem;
  }
  margin-bottom: ${props => props.theme.spacing.m}rem;
  ${props => props.theme.setFontWithRhythm(props.theme.font.caption.family, props.theme.font.caption.size, props.theme.font.caption.lineHeight)}
}


a{
  font-size: inherit;
  font-family: inherit;
  line-height: ${props => props.theme.font.p.lineHeight};
  color: ${props => props.theme.color.first};
  text-decoration: none;

  &:hover{
    text-decoration: none;

  }

}

.alert{
  position: absolute;
  width: 100%;
  z-index: 10000000;

  p{
    margin: 0;
    padding: 0;
  }
}

.alert-dismissible .close{
  padding: .75rem 1.25rem;
}


.file-input-wrapper {
    width: 200px;
    height: 40px;
    overflow: hidden;
    position: relative;
  }
  .file-input-wrapper > input[type="file"] {
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
  .file-input-wrapper > .btn-file-input {
    display: inline-block;
    width: 200px;
    height: 40px;
  }

.form-group{
  margin-bottom:  ${props => props.theme.spacing.s}rem;
}

.block-img, .video-embed{
  margin: ${props => props.theme.spacing.m}rem 0;
}

.cls-1 {
  fill: ${props => props.theme.color.fourth};
}

.cls-1, .cls-2, .cls-3, .cls-4 {
  fill-rule: evenodd;
}

.cls-2 {
  fill: ${props => props.theme.color.third};
}

.cls-3 {
  fill: ${props => props.theme.color.second};
}

.cls-4 {
  fill: ${props => props.theme.color.first};
}


.section{
  position: relative;
}

.center{
  text-align: center;
}

.color_section .btn {
  background-color: white;
}

.section{
  /* //padding: ${props => props.theme.spacing.m}rem 0; */
}

.full{
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;

  ${breakpoint("md")`
     height: 100%;
  `}
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }



  .error {
    display: block;
    font-size: 15px;
    padding: 10px;
    position: relative;
    min-height: 2.64286em;
    background: #fbefee;
    color: #c23628;
  }
  
  .card {
    //padding: $spacing-s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: ${props => props.theme.spacing.m};
  }

  .shop{
    .quote{
      width: 100%;
      background: $second;
      color: White;
    }
  }
    .product-options {
  
     
  
      .color_option {
        background-color: ${props => props.theme.color.first};
        border: 3px solid #FFF;
        border-radius: 18px;
        float: left;
        margin-right: 20px;
        height: 30px;
        width: 30px;
        transition: all 0.5s;
  
        &.blue {
          background: ${props => props.theme.color.first};
          &.active{
            box-shadow: 0 0 0 2px ${props => props.theme.color.first};
          }
          /* //outline: 2px solid ${props => props.theme.spacing.m}; */
        }
  
        &.slate {
          background: #666;
          &.active{
            box-shadow: 0 0 0 2px #666;
          }
          /* //outline: 2px solid #333; */
        }
  
        &.black {
          background: #000;
          &.active{
            box-shadow: 0 0 0 2px #000;
          }
          /* //outline: 2px solid #000; */
        }
  
        &.white {
          background: white;
          border: 1px solid #e3e3e3;
          &.active{
            box-shadow: 0 0 0 2px #000;
          }
          /* //outline: 2px solid #000; */
        }
      }
    }
  }
  

  .product-options{
    padding: ${props => props.theme.spacing.xs} 5px;
    overflow: hidden;
    margin-bottom: ${props => props.theme.spacing.xs}rem;
  }


  .Product__buy, .Product__price{
    margin-top: ${props => props.theme.spacing.xs}rem;
  }


.nav-content{

}

.navbar{

  div, a, img, button{
    transition: all 0.3s linear;
  }

  .nav-link{
    float: left;
  }

  background: #fff;
  padding: ${props => props.theme.spacing.xs}rem;
  border-bottom: 1px solid #f7f6f6;

  .navbar-brand{
    width: 120px;
  }

  .nav-item a, .nav-item .nav-link{
    font-size: ${props => props.theme.font.navLink.size}rem;
    font-family: ${props => props.theme.font.navLink.family};
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;

    ${breakpoint("lg")`
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    `}

    &.active{
      color: ${props => props.theme.color.first};
    }

    .expand &{
      font-size: ${props => props.theme.font.navLink.size*0.7}rem;
    }
  }


  .nav-item button{
    font-size: 0.7rem;
    font-family: ${props => props.theme.font.navLink.family};
    padding: 0.3rem 1.5rem;
    margin-top: ${props => props.theme.spacing.m}rem;
    position: relative;
  }

  &.expand{
    padding: 0 ${props => props.theme.spacing.xs}rem;
    height: 35px;

    div, a, img, button{
      transition: all 0.3s linear;
    }

    .navbar-toggler{
      padding:.2rem .75rem;
    }

    .navbar-toggler-icon{
      width: 0.7em;
      height: -0.5em;
    }

    .navbar-brand{
      width: 70px;
      padding: 0;
    }

    .nav-item a, .nav-item .nav-link{
      padding: ${props => props.theme.spacing.xs}rem;
      font-size: ${props => props.theme.font.navLink.size*0.7}rem;
      font-family: ${props => props.theme.font.navLink.family};
    }

    .nav-item button{
      font-size: ${props => props.theme.font.navLink.size}rem;
      font-family: ${props => props.theme.font.navLink.family};
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      padding-top: 0.2rem;
      padding-bottom: 0.1rem;
      top: -2px;
      position: relative;
    }
  }

  .navbar-toggler{
    background-color: ${props => props.theme.color.first};
  }

  .navbar-collapse{
    .navbar-nav{

      .nav-item{
        text-align: center;
        padding: ${props => props.theme.spacing.xxs}rem;
      }
    }
  }
}

html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 6 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 17px;
  }
}

.pointer_row{
  position: relative;
}

.pointer.before {
  transition: all 0.3s linear;
  z-index: 10;
  box-sizing: border-box;
  position: absolute;
  top: -40px;
  display: block;
  height: 20px;
  border: 20px solid transparent;
  border-bottom-color: white;
  pointer-events: none;
  width: 50;

}

.pointer.after {
  transition: all 0.3s linear;
  z-index: 10;
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: -40px;
  display: block;
  height: 20px;
  border: 20px solid transparent;
  border-bottom-color: white;
  pointer-events: none;
}

.pointer.before {
  left: 0;
  border-right: 20px solid transparent;
  border-left: 0;
  pointer-events: none;
}

.pointer.after {
  right: 0;
  border-left: 20px solid transparent;
  border-right: 0;
  pointer-events: none;
}

.feature_caption{
  padding: ${props => props.theme.spacing.s}rem 0 ${props => props.theme.spacing.l}rem;
  text-align: center;

  .feature-icon{
    max-width: 60px;
    margin: 0 auto;
  }
}


.nav-tabs{
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  border: 0;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-link{
  border: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
  border: 0;
  border-bottom: 1px solid black;
}

.nav-tabs .nav-item:first-of-type .nav-link{
  padding-left: 0;
}

.nav-link{
  margin-bottom: 0;
  line-height: 1;
  font-size: 0.8rem;
  margin-bottom: ${props => props.theme.spacing.s}*0.1rem;
  font-family: "AvenirLTBold";
  letter-spacing: 0.001rem;

  &.active{
    color:  ${props => props.theme.color.first} !important;
  }
  
}

.tab-pane{
  position: absolute;
}

.tab-content{
  margin: $spacing-xs 0;
}

.tab-content>.tab-pane{
  display:block;
  visibility:hidden;
}

.tab-content>.active{
  visibility:visible;
}


  
     
  
  .color_option {
    background-color: ${props => props.theme.color.first};
    border: 3px solid #FFF;
    border-radius: 18px;
    float: left;
    margin-right: 20px;
    height: 30px;
    width: 30px;
    transition: all 0.5s;

    &.blue {
      background:  ${props => props.theme.color.first};;
      &.active{
        box-shadow: 0 0 0 2px  ${props => props.theme.color.first};;
      }
      //outline: 2px solid  ${props => props.theme.color.first};;
    }

    &.slate {
      background: #666;
      &.active{
        box-shadow: 0 0 0 2px #666;
      }
      //outline: 2px solid #333;
    }

    &.black {
      background: #000;
      &.active{
        box-shadow: 0 0 0 2px #000;
      }
      //outline: 2px solid #000;
    }

    &.white {
      background: white;
      border: 1px solid #e3e3e3;
      &.active{
        box-shadow: 0 0 0 2px #000;
      }
      //outline: 2px solid #000;
    }
  }


`;



import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import PrismicReact from "prismic-reactjs";
import SubNav from "../Components/Elements/SubNav";
import Cart from "./Cart";
import { openCart, closeCart } from "../State/cart/actions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { IconContext } from "react-icons";
import { FiShoppingCart } from "react-icons/fi";

const NavItems = ({ document, linkResolver }) => {
  const { data: { body = false } = {} } = { ...document };
  return body
    ? body.map((navitem, key) => {
        let {
          navigation_link,
          navigation_name,
          internal_link
        } = navitem.primary;
        return (
          <NavItem>
            <NavLink
              key={key}
              to={
                internal_link
                  ? internal_link
                  : PrismicReact.Link.url(navigation_link, linkResolver)
              }
            >
              {navigation_name[0].text}
            </NavLink>
          </NavItem>
        );
      })
    : null;
};

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.checkout = this.checkout.bind(this);

    this.containerLine = React.createRef();

    this.state = {
      collapsed: false,
      doc: null,
      notFound: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentWillMount() {
    this.fetchNav(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchNav(props);
  }

  handleScroll(event) {
    console.log("Should collapse")

    if (window.scrollY > 60) {
      this.setState({ expand: true, collapsed: false });
    } else {
      this.setState({ expand: false, collapsed: false });
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  checkout(e) {
    e.preventDefault();
    this.props.openCart();
  }

  fetchNav(props) {
    return (
      (props.prismicCtx &&
        props.prismicCtx.api.getByUID(
          "main_navigation",
          "navigation-main",
          (err, doc) => {
            if (doc) {
              this.setState({ doc });
            } else {
              this.setState({ notFound: !doc });
            }
          }
        )) ||
      false
    );
  }

  linkResolver = function(doc) {
    if (doc.type === "blog") return "/post/" + doc.uid;
    if (doc.type === "page" || "homepage") return "/" + doc.uid;
    return null;
  };

  render() {
    const { doc, isOpen, expand } = this.state;
    const { lineItems, openCart } = this.props;
    const {
      location: { pathname }
    } = this.props;
    const { toggleNavbar, linkResolver } = this;
    return (
      <>
        <Cart />
        {pathname !== "/stream" ? (
          <Navbar
            fixed="top"
            expand="md"
            dark
            className={expand ? "expand" : ""}
          >
            <NavbarBrand href="/">
              <Logo />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse isOpen={this.state.collapsed} navbar>
              <Nav className="ml-auto" navbar>
                <NavItems document={doc} linkResolver={linkResolver} />
                <NavItem>
                  <NavLink onClick={openCart} to="#">
                    <IconContext.Provider value={{ color: "black", size: 20 }}>
                      <FiShoppingCart
                        style={{ marginRight: 10, marginTop: -3 }}
                      />
                    </IconContext.Provider>
                    {`${lineItems.length} Items`}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/login">Login</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
            <SubNav />
          </Navbar>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.cart.isOpen,
    lineItems: state.cart.checkout.lineItems || []
  };
};

const mapDispatchToProps = {
  openCart,
  closeCart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);

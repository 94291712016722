import shadowReducer from "./reducers";

import * as shadowOperations from "./operations";
import * as shadowTypes from "./types";

export {
    shadowOperations,
    shadowTypes
};

export default shadowReducer;

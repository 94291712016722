import { combineEpics, ofType } from 'redux-observable';
import { of, from, interval, merge } from 'rxjs';
import {
  mergeMap,
  timeout,
  takeUntil,
  concatAll,
  tap,
  map,
  mapTo,
  catchError,
  filter,
  take,
  flatMap,
  switchMap
} from 'rxjs/operators';
import { createJot, jotSend, jotGet } from './operations';
import { apiGetJot, apiSendJot} from '../../Api';

import {
  JOT_CREATE_REQUEST,
  JOT_CREATE_SUCCESS,
  JOT_CREATE_ERROR,
  JOT_SEND_REQUEST,
  JOT_SEND_ERROR,
  JOT_SEND_SUCCESS,
  JOT_SEND_CANCEL,
  JOT_DRAFT_ADD,
  JOT_SEND_WAITING,
  ADD_TO_JOTO
} from './types';
import { SHADOW_UPDATE } from '../shadows/types';
import { jotCreateSuccess } from './actions';

const jotCreateEpic = action$ =>
  action$.pipe(
    ofType(JOT_CREATE_REQUEST),
    mergeMap(action => {
      let {svg, params} = action;
      return from(createJot(svg, params)).pipe(
        flatMap(jot =>
          {
            const {jotId} = jot;
            return interval(1000).pipe(
              flatMap(_ => apiGetJot(jotId)),
              tap(response => console.log(response)),
              filter(response => typeof response.gcodeFilename !== 'undefined'),
              map(response => jotCreateSuccess(response)),
              take(1),
              timeout(20000),
              catchError(error =>
                of({
                  type: JOT_CREATE_ERROR,
                  payload: error,
                  error: true
                })
              )
            )
          }
          
        )
      )
    }
      
    )
  );

const draftJotEpic = action$ =>
  action$.pipe(
    ofType(JOT_CREATE_SUCCESS),
    // tap(()=> NavigationService.navigate('Home')),
    map(jot => ({
      type: JOT_DRAFT_ADD,
      jot
    }))
  );

const addJotEpic = action$ =>
  action$.pipe(
    ofType(ADD_TO_JOTO),
    // tap(()=> NavigationService.navigate('Home')),
    map(jot => ({
      type: JOT_DRAFT_ADD,
      jot
    }))
  );
  
  
const waiting$ = from([5, 10, 15]).pipe(
  map(x => interval(x * 1000).pipe(take(1))),
  concatAll(),
  mapTo({ type: JOT_SEND_WAITING })
);


const jotSendEpic = (action$, state$) =>
  action$.pipe(
    ofType(JOT_SEND_REQUEST),
    tap(action => {
      const deviceId = state$.value.devices.selected;
      const { jotId } = state$.value.create.draftJot;
      apiSendJot(deviceId, jotId).catch(err => console.log(err));
    }),
    switchMap(action =>
      merge(
        action$.pipe(
          ofType(SHADOW_UPDATE),
          take(1),
          mapTo({ type: JOT_SEND_SUCCESS })
        ),
        waiting$
      ).pipe(
        timeout(20000),
        takeUntil(action$.pipe(filter(x => x.type === JOT_SEND_SUCCESS))),
        catchError(error =>
          of({
            type: JOT_SEND_ERROR,
            payload: error,
            error: true
          })
        )
      )
    )
  );

export default combineEpics(jotCreateEpic, jotSendEpic, draftJotEpic, addJotEpic);

import axios from 'axios';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

const axiosInstance = axios.create({
  baseURL: 'http://192.168.4.1',
  timeout: 30000
});

const saveConfig = () =>
  axiosInstance({
    method: 'post',
    url: '/rpc/Config.Save',
    data: JSON.stringify({ reboot: true })
  })
  .catch(error => console.log(error));

const setConfig = config => axiosInstance({
    method: 'post',
    url: '/rpc/Config.Set',
    data: JSON.stringify(config, false)
  })
  .then(response =>{
    console.log(response);
    return response;
  })
  .catch(error => console.log(error));

export const isPairingNetwork = () =>
  axiosInstance
    .get()
    .then(response => response.status === 200)
    .catch(error => console.log(error));

export const scanWifiNetworks = () =>
  axiosInstance
    .get('/rpc/Wifi.Scan')
    .then(response => Promise.resolve(_.uniqBy(response.data, 'ssid')))
    .catch(err => Promise.reject(err));


export const pairDevice = async ({ ssid, password, username }) => {
  const credentials = await Auth.currentCredentials();
  return setConfig({
    config: {
      userId: {
        id: `${username}=${credentials._identityId}`
      },
      wifi: {
        sta: {
          enable: true,
          ssid,
          pass: password
        },
        ap: {
          enable: false
        }
      }
    }
  })
  .then(response=> {
    // TODO: check for null?
    console.log(JSON.stringify(response, false));
    return saveConfig()
  })
  .catch(err => console.log(err));
};

import * as Banner from "../Styled/Banner";
import { Container, Row, Col } from "reactstrap";
import PrismicReact from "prismic-reactjs";
import React from "react";

const BannerComponent = ({
  subtitle,
  image,
  title,
  text,
  background = "white",
  prismic
}) => {
  text = text ? PrismicReact.RichText.asText(text) : null;
  title = title ? PrismicReact.RichText.asText(title) : null;
  subtitle = subtitle ? PrismicReact.RichText.asText(subtitle) : null;
  console.log("IMAGE", image);
  return (
    <Row className={"justify-content-center align-self-stretch"}>
      <Banner.Container img={image.full} background={background}>
        {image.left && <Banner.Image className={"d-none d-sm-none d-md-block"} align={"right"} img={image.left.url} />}
        <Banner.Info>
          {subtitle && <Banner.Category>{subtitle}</Banner.Category>}
          {title && <Banner.Title>{title}</Banner.Title>}
          {text && <Banner.Paragraph>{text}</Banner.Paragraph>}
        </Banner.Info>
        {image.right && <Banner.Image align={"right"} img={image.right.url} />}
      </Banner.Container>
    </Row>
  );
};

export default BannerComponent;

import semver from 'semver';
import { createSelector } from 'reselect';

const shadowsSelector = state => state.shadows.byId;
const deviceSelector = state => state.devices.byId;
const deviceArraySelector = state => state.devices.allIds;
const currentDeviceSelector = state => state.devices.selected;
const draftJotSelector = state => state.create.draftJot;
const firmwareSelector = state => state.firmware.latest;

const getStatusMessage = (reported, currentDevice) => {
  let {
    status,
    shadowConnected,
    ota: { status: otaStatus, message }
  } = reported;
  status = !shadowConnected ? 'disconnected' : status;
  status = otaStatus ? 'updating' : status;

  switch (status) {
    case 'idle':
      return false;
    case 'disconnected':
      return { status: status, message: 'Disconnected' };
    case 'connecting':
      return { status: status, message: 'Connecting' };
    case 'drawing':
      return { status: status, message: 'Busy jotting' };
    case 'updating':
      return { status: status, message: `Updating - ${message}` };
    case 'downloading':
      return { status: status, message: 'Sending jot' };
    default:
      return null;
  }
};

const checkForUpdate = (deviceShadow, latestFirmware) => {
  const { version, url } = latestFirmware;
  const currentFirmware =
    deviceShadow &&
    deviceShadow &&
    deviceShadow.ota &&
    deviceShadow.ota.fw_version;
  const cleanVersion = (version && semver.clean(version)) || false;
  const cleanCurrent = (currentFirmware && semver.clean(currentFirmware)) || false;
  const updateReqired = cleanVersion && cleanCurrent && semver.lt(cleanCurrent, cleanVersion);
  return updateReqired ? { url, version } : false;
};

const getProgress = (reported, status) => {
  const progress = status === 'updating' ? reported.ota.progress_percent : reported.progress;
  return progress !== 0 ? progress/100 : progress;
};

const showProgress = (reported, status) => {
  let showProgressVar = (
    (typeof reported.progress !== 'undefined' &&
      reported.status !== 'idle' &&
      reported.status !== 'downloading') ||
      status === 'updating'
  );
  return showProgressVar;
};

const shadowToStatus = (shadow, deviceId, firmware) => {
  let { status, message } = getStatusMessage(shadow.reported);
  let { progress } = getProgress(shadow.reported, status);
  return shadow.reported
    ? {
        deviceId,
        currentJot: shadow.jot || false,
        connected: shadow.reported.shadowConnected || false,
        progress: getProgress(shadow.reported, status),
        update: checkForUpdate(shadow.reported, firmware),
        showProgress: showProgress(shadow.reported, status),
        showStatus:
          typeof shadow.reported.status !== 'undefined' && shadow.reported.status !== 'idle',
        message: message,
        activity:
          shadow.reported.status === 'idle' || typeof shadow.reported.status === 'undefined'
            ? false
            : shadow.reported.status
      }
    : null;
};

export const getCurrentStatus = createSelector(
  [shadowsSelector, currentDeviceSelector, firmwareSelector],
  (shadows, currentDevice, firmware) => {
    return shadows[currentDevice] && shadows[currentDevice].reported
      ? shadowToStatus(shadows[currentDevice], currentDevice, firmware)
      : { connected: false };
  }
);

export const getDeviceStatus = deviceId => {
  return createSelector(
    [shadowsSelector, firmwareSelector],
    (shadows, firmware) => {
      return shadows[deviceId] && shadows[deviceId].reported
        ? shadowToStatus(shadows[deviceId], deviceId, firmware)
        : { connected: false };
    }
  );
};

export const getCurrentDeviceActivity = createSelector(
  [shadowsSelector, currentDeviceSelector],
  (shadows, currentDevice) =>
    shadows[currentDevice] && shadows[currentDevice].reported
      ? shadows[currentDevice].reported.status !== 'idle'
      : false
);

export const getDraftJot = createSelector(
  [draftJotSelector],
  draftJot => draftJot
);

export const getCurrentDevice = createSelector(
  [currentDeviceSelector, deviceArraySelector],
  (currentDevice, devices) => {
    return currentDevice && devices.length ? currentDevice : 'No devices';
  }
);

export const getDevicesStatus = createSelector(
  [deviceSelector, shadowsSelector, firmwareSelector],
  (devices, shadows, firmware) => {
    const ids = Object.keys(devices);
    return ids
      ? ids.map(id => {
          return shadowToStatus(shadows[id], id, firmware);
        })
      : null;
  }
);

export const getNumberOfDevices = createSelector(
  [deviceArraySelector],
  devices => {
    const totalDevices = devices ? devices.length : false;
    return totalDevices;
  }
);

import devicesReducer from "./reducers";

import * as devicesSelectors from "./selectors";
import * as devicesOperations from "./operations";
import * as devicesTypes from "./types";

export {
    devicesOperations,
    devicesTypes,
    devicesSelectors
};

export default devicesReducer;

import React from "react";
import { Row, Col } from "reactstrap";
import {
  ProductImage,
  VariantSelectors,
  Features,
  Feature,
  Specifications,
  Spec,
  ProductPrice,
  ProductTitle,
  ProductDescription
} from "../../Components/Shopify/ProductComponents";

import BuyButton from "../Styled/BuyButton";

import { compose, withState, withHandlers, withProps } from "recompose";
import { Tabs } from "./Tabs";
import {connect} from 'react-redux';


const LeadDevice = ({
  client,
  product,
  variantSelectors,
  selectedVariantQuantity,
  selectedOptions,
  selectedVariant,
  selectedVariantImage,
  addVariantToCart,
  handleOptionChange,
  handleQuantityChange
}) => {
  let { title, descriptionHtml, images = [], options, variants = [] } = product;
  let variantImage = selectedVariantImage || images[0];
  let variant = selectedVariant || variants[0];
  let variantQuantity = selectedVariantQuantity || 1;
  let { price, id } = variant;
  return (
    <>
      <Col md={{ size: 5, offset: 1, order: 1 }} xs={{ size: 12, order: 2 }}>
        <Row>
          <Col>
            <ProductTitle>{title}</ProductTitle>
            <Tabs>
              <ProductDescription title={"Overview"}>
                <span dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
              </ProductDescription>
              <Features title={"Features"}>
                <Feature>WiFi Connected</Feature>
                <Feature>Eraseable surface</Feature>
                <Feature>Easily create on an app</Feature>
              </Features>
              <Specifications title={"Specs"}>
                <Spec>
                  <strong>Dimensions</strong> - 393mm x 472mm x 88mm
                </Spec>
                <Spec>
                  <strong>Weight</strong> - 3 kg
                </Spec>
                <Spec>
                  <strong>Ink Color</strong> - Black
                </Spec>
                <Spec>
                  <strong>Wireless</strong> - 802.11 b/g/n
                </Spec>
                <Spec>
                  <strong>Inputs</strong> - Wifi Connection / USB-B Micro Serial
                </Spec>
                <Spec>
                  <strong>App</strong> - Apple iOS/Google Android
                </Spec>
                <Spec>
                  <strong>Power</strong> - 63W USB-C
                </Spec>
              </Specifications>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <VariantSelectors
              selectedOptions={selectedOptions}
              options={options}
              handleOptionChange={handleOptionChange}
              variantSelectors={variantSelectors}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <ProductPrice>£{price}</ProductPrice>
          </Col>
          <Col md="6">
            <BuyButton
              onClick={() => addVariantToCart(id, variantQuantity)}
            >Add To Cart</BuyButton>
          </Col>
        </Row>
      </Col>
      <Col md={{ size: 5, order: 2 }} xs={{ size: 12, order: 1 }}>
        <ProductImage images={images} variantImage={variantImage} alt={title} />
      </Col>
    </>
  );
};





const withOptionChange = withHandlers({
  handleOptionChange: ({
    client,
    product,
    selectedVariant,
    setSelectedVariant,
    setSelectedVariantImage,
    selectedOptions
  }) => (event, type, value) => {
    if (type === "color") {
      selectedOptions["Color"] = value;
    } else {
      const { target } = event;
      selectedOptions[target.name] = target.value;
    }
    console.log(client.product.helpers);
    selectedVariant = client.product.helpers.variantForOptions(
      product,
      selectedOptions
    );
    setSelectedVariant(selectedVariant);
    //setSelectedVariantImage(selectedVariant.attrs.image);
  }
});

const withQuantityChnage = withHandlers({
  handleQuantityChange: ({ setSelectedVariantQuantity }) => event =>
    setSelectedVariantQuantity(event.target.value)
});

const withFindImage = withHandlers({
  findImage: props => (images, variantId) => {
    const primary = images[0];
    const image = images.filter(function(image) {
      return image.variant_ids.includes(variantId);
    })[0];
    return (image || primary).src;
  }
});

const withSelectedOptionsState = withState(
  "selectedOptions",
  "setSelectedOptions",
  ({ product }) => {
    let { options } = product;
    let defaultOptionValues = {};
    options.forEach(
      selector =>
        (defaultOptionValues[selector.name] = selector.values[0].value)
    );
    return defaultOptionValues;
  }
);

const enhance = compose(
  withState("selectedVariant", "setSelectedVariant", null),
  withState("selectedVariantImage", "setSelectedVariantImage", false),
  withState("selectedOptions", "setSelectedOptions", null),
  withState("selectedVariantQuantity", "setSelectedVariantQuantity", null),
  withSelectedOptionsState,
  withOptionChange,
  withQuantityChnage,
  withFindImage
);

export default enhance(LeadDevice);

import React from 'react';
import PrismicReact from 'prismic-reactjs';
import {Title, SubTitle, BodyText} from '../Styled/Type';
import BuyButton from '../Styled/BuyButton';

const TextStandard = ({block_maintitle, block_section, block_text, button_link, button, ...rest}) => {
  return(
    <div className="text-standard">
        <SubTitle>{PrismicReact.RichText.asText(block_section)}</SubTitle>
        <Title>{PrismicReact.RichText.asText(block_maintitle)}</Title>
        <BodyText>{PrismicReact.RichText.asText(block_text)}</BodyText>
          { button.length ?
            <BuyButton href={button_link.url}>{PrismicReact.RichText.asText(button)}</BuyButton>
          : null }
    </div>
  )
}

export default TextStandard;
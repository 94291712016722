import Amplify, {API} from 'aws-amplify';

export const identityPoolId = 'eu-west-1:13ddb7c0-b47f-4874-a225-f156a26e88ea';
export const region = 'eu-west-1';
export const userPoolId = 'eu-west-1_oPhJcbTMm';
export const clientId = '63p6ml7r5l3sq1uun51ksn1ed';
export const debugLevel = 'deubg';
export const mqttDebugLevel = true;
export const s3Bucket = 'platform-jots';
export const apiName = 'MyAPI';
export const apiEndpoint = 'https://api.joto.io';
export const iotEndpoint = 'a1k0tdyi37dcvg-ats.iot.eu-west-1.amazonaws.com';
export const stage = "production";
// //IOT CONFIG OBJECT
// export default {
//   'awsIotHost': iotEndpoint,
//   'awsRegion': region,
//   'awsCognitoUserPoolId': userPoolId,
//   'awsCognitoUserPoolAppClientId': clientId,
//   'awsCognitoIdentityPoolId': identityPoolId,
//   'logLevel': debugLevel,
//   'mqttDebugLevel': mqttDebugLevel
// };

//AMPLIFY CONFIG OBJECT
export const AmplifyConfig = {
  Auth: {
    identityPoolId: identityPoolId, //REQUIRED - Amazon Cognito Identity Pool ID
    region: region, // REQUIRED - Amazon Cognito Region
    userPoolId: userPoolId, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: clientId, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    bucket: s3Bucket, //REQUIRED -  Amazon S3 bucket
    region: region, //OPTIONAL -  Amazon service region
    identityPoolId: identityPoolId //Specify your identityPoolId for Auth and Unauth access to your bucket;
  },
  API: {
    endpoints: [
      {
        name: apiName,
        endpoint: apiEndpoint
      },
      {
        name: "IOT",
        endpoint: `https://${iotEndpoint}`,
        service: 'iotdata'
      }
    ],
    region: region //OPTIONAL -  Amazon service region
  },
  PubSub : {
    aws_pubsub_region: region,
    aws_pubsub_endpoint: `wss://${iotEndpoint}/mqtt`
  }
}

Amplify.configure(AmplifyConfig);



import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Container, Row, Col, CardDeck } from "reactstrap";
import { Title, SubTitle, BodyText, Link } from "../Components/Styled/Type";
import {profileRequest} from "../State/profile/actions";
import {Storage} from "aws-amplify";
import Avatar from "react-avatar";
import Modal from "./Modal";
import ProfileForm from "./ProfileForm";
import { PulseLoader } from "react-spinners";




const Profile = styled.div`
  padding-top: ${props => props.theme.spacing.m}vw;
  padding-bottom: ${props => props.theme.spacing.l}vw;
`;

const getURL = (image) =>{
  return Storage.get(image)
    .then(result => {
      return result
    })
    .catch(err => console.log(err));
} 

const mapStateToProps = state => {
  return {
    profile: state.profile.info
  };
};

const mapDispatchToProps = {
  profileRequest
};


const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycle({
    componentDidMount(){
      this.props.profileRequest();
    }
  })
);

const ProfileInfo = ({ profile, profile : {firstname, lastname, handle, socials, image = false, website, bio}}) => {
  return (
    <Profile>
      {  firstname ?
      <Row>
        <Col md={{ size: 3, offset: 0 }}>
        <Avatar round size={160}  src={`http://joto.imgix.net/public/${image}`}/>
        <Modal content={<ProfileForm />} buttonLabel="Edit Profile"/>
        </Col>
        <Col md={{ size: 8 }}>
          <Title>{`${firstname} ${lastname}`}</Title>
          <SubTitle>{handle}</SubTitle>
          <BodyText>
            {bio || "Not set"}
          </BodyText>
          <Link>{website}</Link>
        </Col>
      </Row>
          : <PulseLoader/>
        }
    </Profile>

  );
};

export default enhance(ProfileInfo);

import React from "react";
import PrismicReact from "prismic-reactjs";
import { Container, Row, Col } from "reactstrap";
import { fetchByID } from "../../Prismic/Helpers";
import { withProps, withState, compose, lifecycle } from "recompose";
import Card from "../Elements/Card";

const Tile = ({
  item: { blog_title, subtitle, excerpt },
  uid,
  img,
  promoted
}) => (
  <Col
    xs={promoted ? 12 : 6}
    md={promoted ? 6 : 4}
    style={{ marginBottom: "2rem" }}
  >
    <Card
      small={!promoted}
      center
      url={img}
      text={promoted ? excerpt : null}
      type={subtitle}
      title={blog_title}
      prismic={true}
      link={`/discover/${uid}`}
    />
  </Col>
);

const enhance = compose(
  withProps(props => console.log("TEST", props)),
  withState("item", "setItem", false),
  withState("uid", "setUID", false),
  lifecycle({
    componentDidMount() {
      fetchByID(this.props.prismicCtx, this.props.id).then(item => {
        this.props.setItem(item.data);
        this.props.setUID(item.uid);
      });
    }
  })
);

const TileComponent = enhance(Tile);

//TODO: This shouldn't require
export const TileCards = ({ slice: { items } = {}, prismicCtx, promoted }) => {
  return (
    <Row>
      {items && promoted
        ? items.map(
            ({
              promoted_blog_post: { id },
              promoted_blog_post_image: { url }
            }) => {
              return (
                <TileComponent
                  promoted
                  id={id}
                  img={url}
                  prismicCtx={prismicCtx}
                />
              );
            }
          )
        : items.map(({ blog_post: { id }, blog_tile_image: { url } }) => {
            return <TileComponent id={id} img={url} prismicCtx={prismicCtx} />;
          })}
    </Row>
  );
};

export default TileCards;

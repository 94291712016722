import React from "react";
import LineItem from "./LineItem";
import { PulseLoader } from "react-spinners";
import ReactPixel from "react-facebook-pixel";
import { withHandlers } from "recompose";

import * as Cart from "../Styled/Cart";
import { Title } from "../Styled/Type";
import Loader from "../Styled/Loader";
import BuyButton from "../Styled/BuyButton";

const LineItems = ({
  lineItems,
  incrementQuantity,
  decrementQuantity,
  removeLineItemInCart
}) => {
  return lineItems.map(line_item => {
    return (
      <LineItem
        decrementQuantity={() => decrementQuantity(line_item)}
        incrementQuantity={() => incrementQuantity(line_item)}
        removeLineItemInCart={() => removeLineItemInCart(line_item)}
        key={line_item.id.toString()}
        line_item={line_item}
      />
    );
  });
};

const enhance = withHandlers({
  openCheckout: ({ checkout }) => () => {
    ReactPixel.track("InitiateCheckout", checkout);
    window.open(checkout.webUrl);
  }
});

const CartComponent = ({
  checkout: { lineItems = [], totalPrice = 0.00 } = {},
  closeCart,
  isOpen,
  loading,
  removeLineItemInCart,
  decrementQuantity,
  incrementQuantity
}) => {
  return (
    <Cart.Container open={isOpen}>
      <Cart.Header>
        <Title>Your cart</Title>
        <Cart.Close onClick={closeCart}>×</Cart.Close>
      </Cart.Header>
      <Cart.Items>
        <LineItems
          lineItems={lineItems}
          removeLineItemInCart={removeLineItemInCart}
          decrementQuantity={decrementQuantity}
          incrementQuantity={incrementQuantity}
        />
      </Cart.Items>
      {loading && (
        <Loader>
          <PulseLoader
            sizeUnit={"px"}
            size={5}
            color={"#000000"}
            loading={loading}
          />
        </Loader>
      )}
      <Cart.Footer>
        <Cart.Total>Total</Cart.Total>
        <Cart.Pricing>
          <span className="pricing">£ {totalPrice}</span>
        </Cart.Pricing>
        {/* <BuyButton onClick={openCheckout}>Checkout</BuyButton> */}
      </Cart.Footer>
    </Cart.Container>
  );
};

export default CartComponent;

//import { Client, Message } from 'react-native-paho-mqtt';
import { Observable, of, from } from 'rxjs';
import { Signer, Credentials } from '@aws-amplify/core';
import { AmplifyConfig } from '../../config';
import { v4 as uuid } from 'uuid';
import * as Paho from 'paho-mqtt';


class ClientsQueue {
  constructor(){
    this.promises = new Map();
  }

  async get(clientId, clientFactory) {
    let promise = this.promises.get(clientId);
    
    if (promise) {
        return promise;
    }

    promise = clientFactory(clientId);
    this.promises.set(clientId, promise);
    return promise;
  }

  allClients() { return Array.from(this.promises.keys()); }

  remove(clientId) {
      this.promises.delete(clientId);
  }
}


export default class MqttOverWSProvider {


    constructor(options) {

      this.options = { ...options };
      this.clientId =  uuid();
      console.log("THIS CLIENT ID", this.clientId);
      this.endpoint =  null;
      this._clientsQueue = new ClientsQueue();

      this.myStorage = {
        setItem: (key, item) => {
          this.myStorage[key] = item;
        },
        getItem: key => this.myStorage[key],
        removeItem: key => {
          delete this.myStorage[key];
        }
      };

    }

    async getSignedURL(){
      const endpoint = AmplifyConfig.PubSub.aws_pubsub_endpoint;
    
      const serviceInfo = {
        service: 'iotdevicegateway',
        region: AmplifyConfig.PubSub.aws_pubsub_region
      };
      const {
        accessKeyId: access_key,
        secretAccessKey: secret_key,
        sessionToken: session_token
      } = await Credentials.get();
      
      this.endpoint = Signer.signUrl(endpoint, { access_key, secret_key, session_token }, serviceInfo);
      return this.endpoint;
    };


    clientId() { return this.options.clientId; }
    clientsQueue() { return this._clientsQueue; }
    getTopicForValue(value) { return typeof value === 'object' && value['topicSymbol']; }
    getProviderName() { return 'MqttOverWSProvider'; }

    onDisconnect(args) {
      console.log("MQTT DISCONNECT", args)
    }

    async newClient({ clientId, url, ...rest }) {
        
        const client =  new Paho.Client(url, clientId);
        console.log("CLIENT", clientId);
        client.onMessageArrived = ({ destinationName: topic, payloadString: msg }) => {
          this._onMessage(topic, msg);
        };

        client.onConnected = () => {
         console.log("MQTT CONNECTED");
        };

        client.onConnectionLost = ({ errorCode, ...args }) => {
            this.onDisconnect({ clientId, errorCode, ...args });
        };

        await new Promise((resolve, reject) => {
            client.connect({
                useSSL: true,
                cleanSession : false, 
                onSuccess: () => resolve(client),
                onFailure : this.onFailedConnect, 
                keepAliveInterval: 10,
                mqttVersion: 3, 
                timeout: 10,
                reconnect : true         // Enable automatic reconnect
            })
        });

        return client;
    }

    onConnectSuccess(resObj) {
      console.log("Initial connect request succeeded.");
    }

    onFailedConnect(err) {
      console.log("Initial connect request failed. Error message : " + err.errorMessage); 
    }

    async connect(clientId, options){
      return await this.clientsQueue().get(clientId, () => this.newClient({ ...options, clientId }));
    }

    async disconnect(clientId) {
      const client = await this.clientsQueue.get(clientId, () => null);

      if (client && client.isConnected()) {
          client.disconnect();
      }
      this.clientsQueue.remove(clientId);
    }

    async publish(topics, msg) {
      const targetTopics = [].concat(topics);
      const message = JSON.stringify(msg);
      const url =  this.endpoint || await this.getSignedURL();
      const client = await this.connect(this.clientId, url);
      targetTopics.forEach(topic => client.send(topic, message));
  }

    _topicObservers = new Map();

    _onMessage(topic, msg) {
      try {
        const observersForTopic = this._topicObservers.get(topic) || new Set();
        const parsedMessage = JSON.parse(msg);
        parsedMessage.topic = topic;
        observersForTopic.forEach(observer => observer.next(parsedMessage));
      } catch (error) {
        console.log(error);
      }
    }

    subscribe(topics, options) {

      const targetTopics = [].concat(topics);
        return Observable.create(observer => {

            targetTopics.forEach(topic => {
                let observersForTopic = this._topicObservers.get(topic);
                if (!observersForTopic) {
                    observersForTopic = new Set();
                    this._topicObservers.set(topic, observersForTopic);
                }
                observersForTopic.add(observer);
            });

            const clientId = this.clientId;;

            (async () => {
                const url =  this.endpoint || await this.getSignedURL();
                const client = await this.connect(clientId, { ...options, url });
                targetTopics.forEach(topic => { client.subscribe(topic); });
            })();

            return () => {
                if (this.client) {
                  
                  targetTopics.forEach(topic => {
                      if (this.client.isConnected()) {
                        this.client.unsubscribe(topic);
                      }
                      const observersForTopic = this._topicObservers.get(topic) || new Set();
                      observersForTopic.forEach(observer => observer.complete());
                      observersForTopic.clear();
                  });

                  this.disconnect();
                }

                return null;
            };
        });
    }
}
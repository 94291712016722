export const CURRENT_VERSION_REQUEST = "app/firmware/CURRENT_VERSION_REQUEST";
export const CURRENT_VERSION_SUCCESS = "app/firmware/CURRENT_VERSION_SUCCESS";
export const CURRENT_VERSION_ERROR = "app/firmware/CURRENT_VERSION_ERROR";


export default {
  CURRENT_VERSION_REQUEST,
  CURRENT_VERSION_SUCCESS,
  CURRENT_VERSION_ERROR
};

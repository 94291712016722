import types from './types';
import { combineReducers } from 'redux';
import _ from 'lodash';

const addShadowJot = (state = [], action) => {
  const { deviceId, jot } = action;
  return { ...state, [deviceId]: { ...state[deviceId], jot: jot } };
};

const addShadowById = (state, action) => {
  const { deviceId } = action;
  return {
    ...state,
    [deviceId]: {}
  };
};

const updateShadowById = (state, action) => {
  const { deviceId, shadow } = action;
  const { desired, reported } = state[deviceId] || {};
  return {
    ...state,
    [deviceId]: {
      ...state[deviceId],
      desired: {
        ...desired,
        ...shadow.desired,
        ota: { ...(desired && desired.ota), ...(shadow.desired && shadow.desired.ota) }
      },
      reported: {
        ...reported,
        ...shadow.reported,
        ota: { ...(reported && reported.ota), ...(shadow.reported && shadow.reported.ota) }
      }
    }
  };
};

const removeShadowById = (state, action) => {
  const { deviceId } = action;
  const { [deviceId]: value, ...newState } = state;
  return {
    ...newState
  };
};

const shadowsById = (state = {}, action) => {
  switch (action.type) {
    case types.SHADOW_UNSUBSCRIBE:
      return removeShadowById(state, action);
    case types.SHADOW_UPDATE:
    case types.SHADOW_CONNECTED:
      return updateShadowById(state, action);
    case types.SHADOW_GET_JOT_SUCCESS:
      return addShadowJot(state, action);
    default:
      return state;
  }
};

const addShadowId = (state, action) => {
  let { deviceId } = action;
  return _.uniq(state.concat(deviceId));
};

const removeShadowId = (state, action) => {
  let { deviceId } = action;
  return _.uniq(state.concat(deviceId));
};

const allShadowIds = (state = [], action) => {
  switch (action.type) {
    case types.SHADOW_UPDATE:
    case types.SHADOW_CONNECTED:
      return addShadowId(state, action);
    case types.SHADOW_UNSUBSCRIBE:
      return removeShadowId(state, action);
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.SHADOWS_SUBSCRIBE:
      return true;
    case types.SHADOW_UPDATE:
    case types.SHADOW_CONNECTED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: shadowsById,
  allIds: allShadowIds,
  loading
});

// devices: {
//   byId: {

//   }
//   allIds: ["JOTO_1231231", "JOTO_1231231"]
//   selected: "JOTO_1231231"
// }

// shadows: {
//   byId: {

//   }
//   allIds: ["JOTO_1231231", "JOTO_1231231"]
// }

// FROM A SELECTOR:

// JOTO_1231231: {
//   jot:
//   draftJot: ""
//   status: "idle"
//   connected: false
//   ping: 123,
//   firmware:
// }

// const original = {
//   foo: 'bar',
//   stack: 'overflow',
// };

// // If the name of the property to remove is constant
// const { stack, ...withoutFirst } = original;
// console.log(withoutFirst); // Will be { "foo": "bar" }

// const { [id]: state[id], ...newState } = state;
// console.log(newState); // Will be { "foo": "bar" }

// // To do a deep removal with property names from variables
// const deep = {
//   foo: 'bar',
//   c: {
//    x: 1,
//    y: 2
//   }
// };

// const parentKey = 'c';
// const childKey = 'y';
// // Remove the 'c' element from original
// const { [parentKey]: parentValue, ...noChild } = deep;
// // Remove the 'y' from the 'c' element
// const { [childKey]: removedValue, ...childWithout } = parentValue;
// // Merge back together
// const withoutThird = { ...noChild, [parentKey]: childWithout };
// console.log(withoutThird); // Will be { "foo": "bar", "c": { "x": 1 } }

import React, { Component } from "react";
import {connect} from "react-redux";
import {
  Alert,
  Container,
  Row,
  Col
} from "reactstrap";
import Card from "../Components/Elements/Card";
import BodyWrapper from "../Components/Styled/BodyWrapper";
import styled from "styled-components";
import ToolBar from "../Components/Svg/Toolbar";
import Paper from "../Components/Svg/Paper";
import CreateForm from "../Containers/CreateForm";
import { jotCreateRequest } from "../State/create/actions";

class AlertExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.setState({ visible: true }));
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss}>
        <p>
          You may see scaling issues if you resize your window while you have
          artwork on the canvas. Please refresh if you experience this.
        </p>
      </Alert>
    );
  }
}

const Profile = styled.div`
  padding-top: ${props => props.theme.spacing.xl}vw;
  padding-bottom: ${props => props.theme.spacing.l}vw;
`;

const AddJot = styled.div`
  justify-content: center;
  align-self: center;
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow: hidden;
`;

class HomepageComponent extends Component {
  constructor(props) {
    super(props);
    this.PaperRef = React.createRef();
    this.state = {
      svg: false
    };
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleJotSubmit = this.handleJotSubmit.bind(this);
  }

  handleChangeFile(file) {
    let _this = this;
    let reader = new FileReader();
    reader.onload = function(event) {
      _this.setState({ svg: event.target.result });
    };
    reader.readAsText(file);
  }

  handleJotSubmit({values}) {
      this.PaperRef.current.exportSVG()
      .then(svg =>
        this.props.jotCreateRequest(svg, values)
        );
  }

  render() {
    return (
      <>
        <AlertExample />
        <BodyWrapper>
          <Container className={"d-flex h-100 flex-grow-1"}>
            <Row className={"justify-content-center align-self-center flex-grow-1"}>
              <Col lg={6}>
                <Card centered create>
                  <Paper ref={this.PaperRef} svg={this.state.svg} />
                </Card>
                <ToolBar loadSVG={this.handleChangeFile}/>
              </Col>
              <Col lg={{ size: 5, offset: 1 }}>
                <h2>A few details...</h2>
                <CreateForm handleJotSubmit={this.handleJotSubmit}/>
              </Col>
            </Row>
          </Container>
        </BodyWrapper>
      </>
    );
  }
}



const mapDispatchToProps = {
  jotCreateRequest
};

export default connect(
  null,
  mapDispatchToProps
)(HomepageComponent);


import React, {Component} from 'react';
import PrismicReact from 'prismic-reactjs';
import {Container, Row, Col} from 'reactstrap';
import Sticky from 'react-stickynode';

// import './CaseStudy.scss';

var RetinaImage = require('react-retina-image');

// import 'debug.addIndicators'
const duration = 1;

const CaseStudy = ({kindex, jot, shape, object, background_colour, main_title, section_title, body_title, height, primary}) =>{

  const {joto} = primary;

  return(
    <React.Fragment>
      <div className="use_case"  style={{zIndex: kindex*1}}>
        <div className="background" id={`background${kindex}`} style={{width: "100%", height: "100%", zIndex: kindex*1, backgroundColor: background_colour}} data-aos-offset={kindex*height*duration-100} data-aos={kindex !== 1 ? "fade-in": null} data-aos-easing="linear" data-aos-duration="200">
          <div className="pattern" style={{width: "100%", height: "100%", backgroundImage: `url(${primary.pattern.url})`, opacity: 0.05}}></div>
        </div>
          <div className="shape" id={`shape${kindex}`} style={{zIndex: kindex*1}} data-aos-offset={kindex*height*duration} data-aos="slide-up" data-aos-easing="linear" data-aos-duration="800">
            {shape && <img className="img-height"  src={shape.url}/>}
          </div>
          <div className="joto_background" id={`joto${kindex}`} style={{zIndex: kindex*1}}data-aos-offset={kindex*height*duration-100}  data-aos={kindex !== 1 ? "fade-in": null} data-aos-easing="linear" data-aos-duration="100">
            <img className="img-height" src={joto.url} srcSet={`${joto.Retina.url} 300w, ${joto.Retina.url} 768w, ${joto.Retina.url} 1280w`} />
            {/* {joto && <img className="img-fluid" src={joto.url}/>} */}
          </div>
        <div className="text vertical-center" id={`text${kindex}`} style={{zIndex: kindex*1}} data-aos-offset={kindex*height*duration}  data-aos={kindex !== 1 ? "fade-in": null} data-aos-easing="linear" data-aos-duration="100">
          <Container>
            <Row>
              <Col sm="4">
                <h1>{PrismicReact.RichText.asText(main_title)}</h1>
                <h4>{PrismicReact.RichText.asText(section_title)}</h4>
                <p>{PrismicReact.RichText.asText(body_title)}</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="object" id={`object${kindex}`} style={{zIndex: kindex*1}} data-aos-offset={kindex*height*duration} data-aos={kindex !== 1 ? "fade-in": null} data-aos-easing="linear" data-aos-duration="200">
            {object && <img className="img-height" src={object.url} srcSet={`${object.Retina.url} 300w, ${object.Retina.url} 768w, ${object.Retina.url} 1280w`} />}

        </div>
        <div className="jot" id={`jot${kindex}`} style={{zIndex: kindex*5}} data-aos-offset={kindex*height*duration} data-aos={kindex !== 1 ? "fade-in": null} data-aos-easing="linear" data-aos-duration="400">
            <img className="img-height" src={jot.url} srcSet={`${jot.url} 300w, ${jot.url} 768w, ${jot.url} 1280w`} />

        </div>
      </div>
    </React.Fragment>
  
  )
}

const CaseStudyLoop = ({items, primary, height}) =>{
  let i = 0;
  return items.map((item, key)=> { 
    i++;
    return(
      <div className="case-study" id={`case-study${i}`} key={key} >
            <CaseStudy kindex={i} primary={primary} {...item} height={height}/>
      </div>
    )
  })
}

const CaseStudies = ({height, slice, position, top, align}) => {
  let {primary, items} = slice;
    return (
            <CaseStudyLoop items={items} primary={primary} height={height}/>
    )
}


class App extends Component {

    constructor(props) {
        super(props);
        // this.controller = new ScrollMagic.Controller();
        this.state = {
            pinText: 'Unpinned.',
            width: 0, 
            height: 0,
            position: "relative",
            align: "top"
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.container = React.createRef();
        this.lastScroll = 0;
        this.scrollRate = 0;
    }

    componentDidUpdate(){
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleScroll() {
      // let top = this.container.current.getBoundingClientRect().top;
      // this.scrollRate =  this.lastScroll - top;
      // if(top < this.scrollRate*1.5 && top > -(this.state.height*(this.props.slice.items.length)*duration)){
      //   console.log("FIX");
      //   this.setState({position : "fixed" });
      //   this.setState({align : "top" });
      // }else if(top > 0 && top > -(this.state.height*(this.props.slice.items.length)*duration)){
      //   console.log("UNFIX UP");
      //   this.setState({position : "absolute" });
      //   this.setState({align : "top" });
      // }else if (top < -(this.state.height*(this.props.slice.items.length)*duration)){
      //   console.log("UNFIX BASE");
      //   this.setState({position : "absolute" });
      //   this.setState({align : "bottom" });
      // }
      // this.lastScroll = top;
    }
    
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    handleStateChange = (status) => {
      if (status.status === Sticky.STATUS_FIXED) {
          console.log('the component is sticky');
      }
  }

    render() {
        const { pinText } = this.state;
        return (

          <Sticky enabled={true} top={550}>
            <div style={{height: this.state.height*5}}>
            <CaseStudies {...this.props} {...this.state} fixed={this.state.position}/>
            </div>
          </Sticky>

        );
    }
}

export default App;

export const DEVICE_SELECT = "app/devices/DEVICES_SELECT";

export const DEVICES_REQUEST = "app/devices/DEVICES_REQUEST";
export const DEVICES_SUCCESS = "app/devices/DEVICES_SUCCESS";
export const DEVICES_ERROR = "app/devices/DEVICES_ERROR";
export const DEVICES_SETUP = "app/devices/DEVICES_SETUP";

//TODO: find out what this is for
export const UPDATE_DEVICE_LIST = "app/devices/UPDATE_DEVICE_LIST";

export const DEVICES_SETTINGS_REQUEST = "app/devices/DEVICES_SETTINGS_REQUEST";
export const DEVICES_SETTINGS_SUCCESS = "app/devices/DEVICES_SETTINGS_SUCCESS";
export const DEVICES_SETTINGS_ERROR = "app/devices/DEVICES_SETTINGS_ERROR";

export const DEVICES_LIST_REQUEST = "app/devices/DEVICES_LIST_REQUEST";
export const DEVICES_LIST_SUCCESS = "app/devices/DEVICES_LIST_SUCCESS";
export const DEVICES_LIST_ERROR = "app/devices/DEVICES_LIST_ERROR";

export default {
  DEVICES_REQUEST,
  DEVICES_SUCCESS,
  DEVICES_ERROR,
  DEVICE_SELECT,
  DEVICES_SETTINGS_REQUEST,
  DEVICES_SETTINGS_SUCCESS,
  DEVICES_SETTINGS_ERROR,
  DEVICES_LIST_REQUEST,
  DEVICES_LIST_SUCCESS,
  DEVICES_LIST_ERROR
};
